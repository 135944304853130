import { Sentinel } from '@energybox/react-ui-library/dist/types';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/sentinels';
import { replaceNullValues } from '../util';

export type Sentinels = {
  isLoading: boolean;
  isLoadingByResourceId: IsLoadingByResourceId;
  byId: SentinelsById;
  sentinelsBySensorId: SentinelsBySensorId;
  sentinelsByEquipmentId: SentinelsByEquipmentId;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type SentinelsById = {
  [id: string]: Sentinel[];
};

export type SentinelsBySensorId = {
  [id: string]: Sentinel[];
};

export type SentinelsByEquipmentId = {
  [id: string]: Sentinel[];
};

const initialState = {
  isLoading: false,
  isLoadingByResourceId: {},
  byId: {},
  sentinelsBySensorId: {},
  sentinelsByEquipmentId: {},
};

export default (state: Sentinels = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SENTINELS_BY_SENSOR_ID_LOADING:
      return assocPath(['isLoadingByResourceId', action.sensorId], true, state);

    case Actions.GET_SENTINELS_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(['isLoadingByResourceId', action.sensorId], false),
        assocPath(
          ['sentinelsBySensorId', action.sensorId],
          action.payload.map(s => replaceNullValues(s))
        )
      )(state);

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_LOADING:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(['isLoadingByResourceId', action.equipmentId], false),
        assocPath(
          ['sentinelsByEquipmentId', action.equipmentId],
          action.payload.map(s => replaceNullValues(s))
        )
      )(state);

    default: {
      return state;
    }
  }
};
