import { SentinelsApiFilter } from '@energybox/react-ui-library/dist/types';
import { Service } from '../config';

const apiBase = '/api/v1/sentinels';

export enum Actions {
  GET_SENTINELS_BY_SENSOR_ID_SUCCESS = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_SUCCESS',
  GET_SENTINELS_BY_SENSOR_ID_ERROR = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_ERROR',
  GET_SENTINELS_BY_SENSOR_ID_LOADING = '@@sentinels/GET_SENTINELS_BY_SENSOR_ID_LOADING',

  GET_SENTINELS_BY_EQUIPMENT_ID_SUCCESS = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_ID_SUCCESS',
  GET_SENTINELS_BY_EQUIPMENT_ID_ERROR = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_ID_ERROR',
  GET_SENTINELS_BY_EQUIPMENT_ID_LOADING = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_ID_LOADING',
}

export const setSentinelFilter = (filter?: SentinelsApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.toString());
  }

  if (filter && filter.siteIds) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.sentinelTypes) {
    queryParams.set('sentinelTypes', filter.sentinelTypes.join(','));
  }

  return queryParams.toString();
};

export const getSentinelsBySensorId = (
  sensorId: string | number,
  filter?: SentinelsApiFilter
) => ({
  type: 'API_GET',
  service: Service.sentinel,
  path: `${apiBase}?${setSentinelFilter(filter)}`,
  success: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_SUCCESS, sensorId },
  error: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_ERROR, sensorId },
  loading: { type: Actions.GET_SENTINELS_BY_SENSOR_ID_LOADING, sensorId },
});

export const getSentinelsByEquipmentId = (
  equipmentId: string | number,
  filter?: SentinelsApiFilter
) => ({
  type: 'API_GET',
  service: Service.sentinel,
  path: `${apiBase}?${setSentinelFilter(filter)}`,
  success: { type: Actions.GET_SENTINELS_BY_EQUIPMENT_ID_SUCCESS, equipmentId },
  error: { type: Actions.GET_SENTINELS_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: { type: Actions.GET_SENTINELS_BY_EQUIPMENT_ID_LOADING, equipmentId },
});
