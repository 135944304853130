import { SensorLineChart } from '@energybox/react-ui-library/dist/components';
import {
  Comment,
  HumiditySop,
  Sensor,
  SensorType,
  Site,
  TemperatureSop,
  ThresholdLine,
  ThresholdLineType,
  UsersById,
} from '@energybox/react-ui-library/dist/types';
import {
  convertSensorTypesToSentinelTypes,
  formatDateForTimeSeriesApi,
  generateXTicks,
  getChartConfig,
  processNotificationsByTypes,
  processSamplesData,
  processThresholdLines,
} from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useMemo } from 'react';
import { useGetFlatNotificationsBySensorId } from '../../../hooks/notifications';
import { useGetOperationalSamples } from '../../../hooks/operationalSamples';
import { useAppLocale, useCurrentUser } from '../../../hooks/useAppDetails';
import useGetCertificationsBySensorId from '../../../hooks/useGetCertificationsBySensorId';
import { CHART_WIDTH_FOR_PDF } from '../../../utils/charts';
import { filterAndProcessComments } from '../../../utils/comments';
import EquipmentChartAnalyticsContainer, {
  EquipmentChartType,
} from '../EquipmentChartAnalyticsContainer';
import SensorInfoContainer from '../SensorInfoContainer';
import styles from './OperationalSensorChartContainer.module.css';
import { ApplicationState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getSentinelsBySensorId } from '../../../actions/sentinels';

type Props = {
  site: Site;
  fromDateISO: string;
  toDateISO: string;
  usersById: UsersById;
  sensor: Sensor;
  comments: Comment[] | undefined;
  tempSop: TemperatureSop | undefined | null;
  humiditySop: HumiditySop | undefined | null;
};

const ChartTitle = {
  [SensorType.TEMPERATURE]: 'Temperature',
  [SensorType.HUMIDITY]: 'Humidity',
  [SensorType.BINARY]: 'Door Access',
};

const ChartTitleToEquipmentChartType = {
  [ChartTitle.TEMPERATURE]: EquipmentChartType.TEMPERATURE,
  [ChartTitle.HUMIDITY]: EquipmentChartType.HUMIDITY,
  [ChartTitle.BINARY]: EquipmentChartType.BINARY,
};

const OperationalSensorChartContainer: React.FC<Props> = ({
  site,
  fromDateISO,
  toDateISO,
  usersById,
  sensor,
  comments,
  tempSop,
  humiditySop,
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const locale = useAppLocale();

  //We split ambient sensors into two separate charts
  //this means two separate "OperationalSensorChartContainer" components
  //each with only one "sensor.types" (either HUMIDITY or TEMPERATURE)
  const sensorType = sensor.types[0];

  useEffect(() => {
    dispatch(getSentinelsBySensorId(sensor.id, { sensorIds: [sensor.id] }));
  }, [sensor]);

  const sentinels = useSelector(({ sentinels }: ApplicationState) => {
    return sentinels.sentinelsBySensorId;
  });

  const sentinelType = convertSensorTypesToSentinelTypes([sensorType]);
  let thresholdLines: ThresholdLine[] = [];
  thresholdLines =
    (currentUser &&
      sensorType === SensorType.TEMPERATURE &&
      processThresholdLines(
        sentinels[sensor.id] ?? [],
        currentUser,
        sentinelType
      )) ||
    [];

  const flatNotifications = useGetFlatNotificationsBySensorId(
    sensor.id,
    fromDateISO,
    toDateISO
  );

  const fromDateUnixTimestampInMilliseconds = useMemo(() => {
    return Date.parse(fromDateISO);
  }, [fromDateISO]);

  const toDateUnixTimestampInMilliseconds = useMemo(() => {
    return Date.parse(toDateISO);
  }, [toDateISO]);

  const { operationalSamplesBySensorId, isLoading } = useGetOperationalSamples(
    String(sensor.id),
    formatDateForTimeSeriesApi(new Date(fromDateISO)),
    formatDateForTimeSeriesApi(new Date(toDateISO))
  );

  const certifications = useGetCertificationsBySensorId(sensor.id);

  const filteredData = useMemo(() => {
    if (!operationalSamplesBySensorId) return [];

    return operationalSamplesBySensorId;
  }, [operationalSamplesBySensorId]);

  const processedData = useMemo(() => {
    if (!currentUser) return [];
    return processSamplesData(filteredData, currentUser);
  }, [currentUser, filteredData]);

  const chartConfig = useMemo(() => {
    if (!currentUser) return {};
    return getChartConfig(sensor.types, currentUser);
  }, [currentUser, sensor]);

  const xTicks = useMemo(() => {
    return generateXTicks(
      fromDateUnixTimestampInMilliseconds,
      toDateUnixTimestampInMilliseconds,
      { ianaTimeZone: site.timeZone }
    );
  }, [fromDateUnixTimestampInMilliseconds, toDateUnixTimestampInMilliseconds]);

  const processedNotifications = useMemo(() => {
    if (!currentUser || !flatNotifications) return undefined;
    return processNotificationsByTypes(flatNotifications, currentUser);
  }, [flatNotifications]);

  const processedComments = useMemo(() => {
    if (!currentUser || !comments) return undefined;
    return filterAndProcessComments(
      comments,
      [sensor.id],
      currentUser,
      usersById,
      sensorType
    );
  }, [currentUser, comments]);

  const chartTitle = useMemo(() => {
    return ChartTitle[sensorType];
  }, [sensor.types]);

  const chartType = ChartTitleToEquipmentChartType[chartTitle];
  const isTempOrHumidityChart =
    chartType === EquipmentChartType.TEMPERATURE ||
    chartType === EquipmentChartType.HUMIDITY;

  return (
    <div>
      <div className={styles.chartTitle}>{chartTitle}</div>

      <EquipmentChartAnalyticsContainer
        site={site}
        fromDateInMilliseconds={fromDateUnixTimestampInMilliseconds}
        toDateInMilliseconds={toDateUnixTimestampInMilliseconds}
        chartType={chartType}
        data={filteredData}
        flatNotifications={flatNotifications}
        tempSop={tempSop}
        humiditySop={humiditySop}
      />

      <SensorInfoContainer sensor={sensor} certifications={certifications} />

      {/* Chart width will look too long on localhost, but need this width for pdf to render chart correctly */}
      <div
        className={isTempOrHumidityChart ? styles.marginTop : ''}
        style={{ width: CHART_WIDTH_FOR_PDF }}
      >
        <SensorLineChart
          isReport
          isLoading={isLoading}
          sensorType={sensor.types}
          locale={locale}
          ianaTimeZoneCode={site.timeZone}
          fromDate={fromDateUnixTimestampInMilliseconds}
          toDate={toDateUnixTimestampInMilliseconds}
          ticks={xTicks}
          chartConfig={chartConfig}
          data={processedData}
          notifications={processedNotifications}
          comments={processedComments}
          hideThresholdLinesCheckbox
          hideNotificationsCheckbox
          hideCommentsCheckbox
          hideControlDetailsCheckbox
          //don't need these for equipment report
          //but need base values
          thresholdLines={thresholdLines}
          zoomIn={() => {}}
          refAreaStart={''}
          refAreaEnd={''}
          updateRefAreaStart={() => {}}
          updateRefAreaEnd={() => {}}
        />
      </div>
    </div>
  );
};

export default OperationalSensorChartContainer;
